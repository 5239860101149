body {
  margin: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.userFile{
  width: 40%;
  height: 80vh;
  border-left: 1px solid #bdbdbd;
  padding-left: 20px;
  padding-right: 20px;
  overflow: scroll;
  overflow-x: hidden;
}

.userFile_mobile{
  width: 100%;
  overflow: scroll;
}

.filePreview{
  width: 35%;
  border-left: 1px solid #bdbdbd;
  padding-left: 20px;
  overflow: scroll
}

.filePreview_mobile{
  width: 100%;
}

.fileName {
  margin: 0;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  margin-top: 10px;
}

.fileName_mobile {
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

.container{
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 500px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100vh;
}

.loginBox{
  margin-bottom: 200px;
}

.mobileTable{

}
@media screen and (max-width: 768px) { 
  body{
    /* background-color: green; */
  }
}
